import React, { lazy, Suspense } from 'react';
import styled, { keyframes } from 'styled-components';
import RoundTextBlack from '../../assets/Rounded-Text-Black.png';
import Loading from '../Loading';

const CoverVideo = lazy(() => import('../CoverVideo'));
const TypeWriterText = lazy(() => import('../TypeWriterText'));

const Section = styled.section`
  min-height: ${props => `calc(100vh - ${props.theme.navHeight})`};
  width: 100vw;
  position: relative;
  background-color: ${props => props.theme.body};
  overflow: hidden;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    @media (max-width: 768px) {
      object-position: center;
    }
  }
`;

const TextContainer = styled.div`
  position: absolute;
  top: 50%; /* Ajusta a posição para centralizada */
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  text-align: left; /* Alinha o texto à esquerda */
  z-index: 1;

  @media (max-width: 768px) {
    top: 15%; /* Ajusta a posição para a parte superior em dispositivos móveis */
    transform: translate(-50%, 0);
  }
`;

const rotate = keyframes`
  100% {
    transform: rotate(1turn);
  }
`;

const Round = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 6rem;
  height: 6rem;
  border: 1px solid ${props => props.theme.text};
  border-radius: 50%;
  z-index: 1;

  img {
    width: 100%;
    height: auto;
    animation: ${rotate} 6s linear infinite reverse;
  }

  @media (max-width: 64em) {
    width: 4rem;
    height: 4rem;
  }

  @media (max-width: 48em) {
    right: 1rem;
  }
`;

const Circle = styled.span`
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${props => props.theme.text};
  color: ${props => props.theme.body};
  font-size: ${props => props.theme.fontxl};

  @media (max-width: 64em) {
    width: 2rem;
    height: 2rem;
    font-size: ${props => props.theme.fontlg};
  }
`;

const Home = () => {
  return (
    <Section id="home">
      <Suspense fallback={<Loading />}>
        <VideoContainer>
          <CoverVideo />
        </VideoContainer>
      </Suspense>
      <Container>
        <TextContainer>
          <Suspense fallback={<Loading />}>
            <TypeWriterText />
          </Suspense>
        </TextContainer>
        <Round>
          <Circle>
            &#x2193;
          </Circle>
          <img width={500} height={400} src={RoundTextBlack} alt="NFT" />
        </Round>
      </Container>
    </Section>
  );
}

export default Home;
